import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import SelectInput from 'components/SelectInput';
import Calendar from 'components/Calendar';
import { getArrays } from 'utils';
import Button from 'components/CustomButtons/Button';
import moment from 'moment/moment';

class Filters extends Component {
  constructor(props) {
    super(props);
    const today = moment().utc().startOf('day');
    this.defFilters = {
      periodFrom: today.unix(),
      periodTo: today.clone().add(1, 'd').unix()
    };
    Object.freeze(this.defFilters);
    this.state = {
      filters: this.defFilters,
      today,
      offset: props.offset,
      mealtype: 0,
    };
  }

  componentDidMount() {
    const { dataArray, socket } = this.props;
    const types = ['typeList', 'dishGroups', 'dishTypes', 'deliveryTime'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
  }

  componentWillReceiveProps(next) {
    if (this.FilterRef && next.offset !== this.state.offset) {
      this.setState({ offset: next.offset });
    }
  }

  pdfButton(periodFrom, periodTo,downloadtype='pdf') {
    const {mealtype} = this.state;
    window.open(
      'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/cooklistpdf?date_from='+periodFrom+'&date_to='+periodTo+'&type='+mealtype+"&downloadtype="+downloadtype,
      '_blank'
    );
  }

  previewButton(periodFrom, periodTo) {
    const {mealtype} = this.state;
    window.open(
      'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/cooklist?date_from='+periodFrom+'&date_to='+periodTo+'&type='+mealtype,
      '_blank'
    );
  }
  exportButton(periodFrom, periodTo) {
    // const {mealtype} = this.state;
    window.open(
      'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/cookingexportlabel?date_from='+periodFrom+'&date_to='+periodTo,
      '_blank'
    );
  }
  render() {
    const { filters, offset, today } = this.state;
    const { dataArray, errors, applyFilters, setSplitter, splitter, mealTypes, activeDishGroups, deliveries } = this.props;
    const onChange = (e, _value, _name) => {
      const name = _name || e.target.name;
      const value = _value || e.target.value;
      this.setState({ filters: { ...this.state.filters, [name]: value }, offset: name === 'sort' ? offset : 0 });
    };
    const { slotsList, typeList, dishGroups } = dataArray;
    const { periodFrom, periodTo, delivery } = filters;
    //console.log(this.state);
    const deliveriesList = { '': 'All', ...(slotsList || []).reduce((acc, { id, name }) => (deliveries.includes(+id) ? { ...acc, [+id]: name } : acc), {}) };
    return (
      <Fragment>
        <form
          ref={el => (this.FilterRef = el)}
          className='fix-on-filters'
          onSubmit={e => {
            e.preventDefault();
            applyFilters(filters, offset);
            return false;
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              applyFilters(filters, offset);
            }
          }}
        > 
          <Calendar
            date={periodFrom || today}
            name={'periodFrom'}
            title={'From date'}
            minDate={moment().utc().startOf('day').unix()}
            onChange={(value, name) => {
              const date = moment.unix(value).utc();
              onChange(undefined, date.isSameOrAfter(today) ? date.unix() : today.unix(), name);
              if (date.isAfter(moment.unix(periodTo))) {
                setTimeout(() => onChange({}, date.unix(), 'periodTo'), 10);
              }
            }}
            controlled
            error={'periodFrom' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 10px 0 4px',
                minWidth: '13%'
              }
            }}
          />
          <Calendar
            date={periodTo || today}
            name={'periodTo'}
            title={'To date'}
            minDate={moment.unix(periodFrom || today).utc().startOf('day').unix()}
            onChange={(value, name) => {
              const date = moment.unix(value).utc();
              const from = filters.periodFrom ? moment.unix(filters.periodFrom) : today;
              onChange(undefined, date.isSameOrAfter(today) && date.isAfter(from) ? date.unix() : today.unix(), name);
            }}
            controlled
            error={'periodTo' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 10px 0 4px',
                minWidth: '13%'
              }
            }}
          />
          <SelectInput
            labelText='Filter by slot'
            items={deliveries.length ? deliveriesList : {}}
            value={delivery || ''}
            error={'delivery' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '13%',
                marginRight: '10px'
              }
            }}
            inputProps={{
              name: 'delivery',
              onChange
            }}
          />
          <SelectInput
            labelText='Filter by Meal type'
            items={Object.keys(typeList || {}).reduce((acc, cur) => mealTypes.includes(+cur) ? { ...acc, [cur]: typeList[cur].title } : acc, {}) || {}}
            value={splitter.type === 'meal' ? splitter.values || [] : []}
            multiple
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '13%',
                marginRight: '10px'
              }
            }}
            inputProps={{
              name: 'meal',
              onChange: (e) => {setSplitter({ type: e.target.name, values: e.target.value }); this.setState({mealtype: e.target.value }); }
            }}
          />
          <SelectInput
            labelText='Filter by Dish type'
            items={Object.keys(dishGroups || {}).reduce((acc, cur) => activeDishGroups.includes(+cur) ? { ...acc, [cur]: dishGroups[cur] } : acc, {})}
            value={splitter.type === 'dish' ? splitter.values || [] : []}
            multiple
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '13%',
                marginRight: '10px'
              }
            }}
            inputProps={{
              name: 'dish',
              onChange: e => setSplitter({ type: e.target.name, values: e.target.value })
            }}
          />
        
          <div className='buttons-on-filters'>
            <Button color='danger' size='large' onClick={() => {
              applyFilters(this.defFilters, offset);
              this.setState({ filters: this.defFilters });
              setSplitter({ type: 'dish', values: [] });
              setSplitter({ type: 'meal', values: [] });
            }}>Clear</Button>
            <Button color='darkBlue' size='large' pullRight type='submit'>Apply</Button>
          </div>
        </form>
        <div className='buttons-on-filters1'>
            <Button color='warning' size='large' type='submit' onClick={() => {this.previewButton(periodFrom, periodTo)}}>Preview PDF</Button>
            <Button color='danger' size='large' type='submit' onClick={() => {this.pdfButton(periodFrom, periodTo,'pdf')}}>Export PDF</Button>
            <Button color='danger' size='large' type='submit' onClick={() => {this.pdfButton(periodFrom, periodTo,'csv')}}>Export CSV</Button>
            <Button color='success' size='large' type='submit' onClick={() => {this.exportButton(periodFrom, periodTo)}}>Export Label XLS</Button>
          </div>
      </Fragment>
    );
  }
}

Filters.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  errors: PropTypes.object,
  offset: PropTypes.number.isRequired,
  applyFilters: PropTypes.func.isRequired,
  setSplitter: PropTypes.func.isRequired,
  splitter: PropTypes.object.isRequired,
  mealTypes: PropTypes.array.isRequired,
  deliveries: PropTypes.array.isRequired,
  activeDishGroups: PropTypes.array.isRequired
};

Filters.defaultProps = {
  offset: 0,
  errors: {}
};

const props = state => ({
  dataArray: state.dataArray
});

export default socketConnect(connect(props, null)(Filters));
